export const CollaborationsData = [
  {
    slug: "fsv",
    title: "FSV",
    date: "Aout 2022",
    shortDescription: "Êtes-vous intéressé(e) par la FoodTech ?",
    homeImg: `${process.env.PUBLIC_URL}/images/home-fsv.jpg`,
    description: "Mission freelance réalisée en collaboration avec Luc, CEO de l'agence El Tigre (Marseille).",
    mainDescription: "Ma première intervention consistait à créer une animation d'entrée sur la page d'accueil ainsi qu'une transition permettant d'afficher le contenu de celle-ci. J'ai également travaillé sur une animation au scroll pour le contenu dont la colonne de droite défile de façon opposé à la colonne de gauche en version desktop, ou sous forme de \"layered pinning\" en version mobile.",
    secondDescription: "Ma seconde intervention s'est déroulée sur la page Team, pour laquelle j'ai développé une fonctionnalité permettant d'afficher une modale comprenant des informations relatives à un membre. Lorsque l'utilisateur clique sur un membre, une animation se met ainsi en place pour déplacer vers la gauche la photo de profil et faire apparaitre la modale via un fadeIn et à l'inverse, lorsque l'utilisateur ferme la modale, la photo de profil revient à sa position initiale.",
    thirdDescription: "Enfin, je suis intervenu sur la page Sustainability pour mettre en place un slider pour la section \"Sustainability Strategy\" ainsi qu'une animation au hover pour la section permettant de télécharger un impact report.",
    imageOne: `${process.env.PUBLIC_URL}/images/collaborations/fsv-1.png`,
    imageTwo: `${process.env.PUBLIC_URL}/images/collaborations/fsv-2.png`,
    imageThree: `${process.env.PUBLIC_URL}/images/collaborations/fsv-3.png`,
    technologies: "Wordpress, Javascript, GSAP",
    client: "El Tigre",
    website: "https://www.fiveseasons.vc/",
    collab: "true",
    nextSlug: "perroquet",
    nextShortDescription: "Un bureau de design graphique, pas l'animal voyons !",
  },
  {
    slug: "perroquet",
    title: "Perroquet",
    date: "Mars 2022",
    shortDescription: "Un bureau de design graphique, pas l'animal voyons !",
    homeImg: `${process.env.PUBLIC_URL}/images/home-perroquet.jpg`,
    description: "Projet fil-rouge réalisé individuellement durant mon stage, puis en collaboration avec Corentin, lead développeur du bureau de design graphique Perroquet (Marseille).",
    mainDescription: "Ma mission principale consistait à effectuer une refonte complète du site et de la structure html. J'ai également travaillé sur l'ajout de nouvelles fonctionnalités / animations, l'UX et le responsive.",
    secondDescription: "Exemple n°1 : sur la page d'accueil et le template des projet, j'ai mis en place un scroll horizontal (GSAP) pour la section présentant sous formats vidéos et/ou images, les réalisations de l'entreprise ainsi qu'une progress-bar qui s'adapte de façon dynamique selon la position au scroll.",
    thirdDescription: "Exemple n°2 : sur différentes pages, j'ai développé des fonctionnalités permettant d'afficher sous forme de modales et de façon dynamique, des informations relatives à la section concernée.",
    imageOne: `${process.env.PUBLIC_URL}/images/collaborations/perroquet-1.png`,
    imageTwo: `${process.env.PUBLIC_URL}/images/collaborations/perroquet-2.png`,
    imageThree: `${process.env.PUBLIC_URL}/images/collaborations/perroquet-3.png`,
    technologies: "Gatsby.js, GraphQL, GSAP",
    client: "Perroquet",
    website: "https://perroquet.eu/",
    collab: "true",
    nextSlug: "homecrush",
    nextShortDescription: "Vous préférez votre bien immobilier avec ou sans jardin ?",
  },
  {
    slug: "homecrush",
    title: "Homecrush",
    date: "Février 2022",
    shortDescription: "Vous préférez votre bien immobilier avec ou sans jardin ?",
    homeImg: `${process.env.PUBLIC_URL}/images/home-homecrush.jpg`,
    description: "Projet réalisé en collaboration avec Corentin, lead développeur du bureau de design graphique Perroquet (Marseille).",
    mainDescription: "Ma mission principale consistait à appliquer une thème CSS responsive sur l'entièreté de l'application web et des sections qui la composent. Ensuite, en collaboration avec Corentin, lead développeur, nous travaillions sur la récupération et l'affichage de contenus issus de la base de données.",
    secondDescription: "Toujours en collaboration avec Corentin, j'ai participé au développement de fonctionnalités concernant la map, qui permet d'afficher les biens immobiliers à la vente selon plusieurs critères.",
    thirdDescription: "Lors de ce projet, j'étais en charge du développement du menu latéral permettant d'accéder aux différentes pages. En mode desktop, le menu est directement visible via des icones et affichera un lien pour chaque icone lorsque l'utilisateur cliquera dessus. En mode mobile, l'utilisateur doit au préalable ouvrir le menu pour interagir avec les différents liens.",
    imageOne: `${process.env.PUBLIC_URL}/images/collaborations/homecrush-1.png`,
    imageTwo: `${process.env.PUBLIC_URL}/images/collaborations/homecrush-2.png`,
    imageThree: `${process.env.PUBLIC_URL}/images/collaborations/homecrush-3.png`,
    technologies: "React.js, MySQL",
    client: "Perroquet",
    website: "https://homecrush.fr/",
    collab: "true",
    nextSlug: "adamis-technologie",
    nextShortDescription: "Avez-vous pensé votre entreprise en toute sécurité ?",
  },
  {
    slug: "adamis-technologie",
    title: "Adamis",
    date: "Février 2022",
    shortDescription: "Avez-vous pensé votre entreprise en toute sécurité ?",
    homeImg: `${process.env.PUBLIC_URL}/images/home-adamis.jpg`,
    description: "Projet réalisé en collaboration avec les membres du bureau de design graphique Perroquet (Marseille).",
    mainDescription: "Ma mission principale consistait à réaliser un scroll horizontal (GSAP), en page d'accueil, pour la section regroupant les différentes solutions proposées par la société Adamis Technologies.",
    secondDescription: "J'ai également participé à la création de pages et l'intégration de celles-ci, la customisation de fonctionnalités comme les sliders, la présentation des articles ou encore des effets au hover.",
    thirdDescription: "Enfin, nous avons travaillé ensemble sur le responsive du site pour que chacune des sections s'adapte parfaitement à tout type d'écran.",
    imageOne: `${process.env.PUBLIC_URL}/images/collaborations/adamis-1.png`,
    imageTwo: `${process.env.PUBLIC_URL}/images/collaborations/adamis-2.png`,
    imageThree: `${process.env.PUBLIC_URL}/images/collaborations/adamis-3.png`,
    technologies: "Wordpress, GSAP",
    client: "Perroquet",
    website: "https://adamis-technologies.fr/",
    collab: "true",
    nextSlug: "mina-Cisse",
    nextShortDescription: "Et vous, quelle est votre vision de l'art ?",
  },
]

export function getCollaborationData(slug) {
  return CollaborationsData.find(
    (data) => data.slug === slug
  );
}

export function isThisCollaboration(slug, collab) {
  return CollaborationsData.find(
    (data) => data.slug === slug && data.collab === collab
  );
}