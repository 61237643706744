import React, { useEffect, useRef } from 'react'
import styled from 'styled-components';

import { COLORS } from '../../utils/Colors';

import {Power4} from 'gsap';

const LoaderContainer = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 0 5%;
  background-color: ${COLORS.gray};

  font-family: "Delirium", sans-serif;
  color: ${COLORS.white};
  font-size: 10vw;
  line-height: 11vw;
  font-weight: 400;
  letter-spacing: 3px;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  scrollbar-width: none;
`;

const FirstParagraph = styled.p`
  width: fit-content;

  @media screen and (min-width:768px){
    font-size: 80px;
    line-height: 85px;
  }
`;

const SecondParagraph = styled.p`
  width: fit-content;

  @media screen and (min-width:768px){
    font-size: 80px;
    line-height: 85px;
  }
`;

const ThirdParagraph = styled.p`
  width: fit-content;

  @media screen and (min-width:768px){
    font-size: 80px;
    line-height: 85px;
  }
`;


export default function TransitionLayout({ timeline }) {

  const loaderTransition = useRef(null);
  const loaderText1 = useRef();
  const loaderText2 = useRef();
  const loaderText3 = useRef();

  const pathname = window.location.pathname;
  const slicedPathname = pathname.substring(1);
  const finalPathname = slicedPathname.replaceAll('-', ' ');

  useEffect(() => {

    if(loaderText2.current !== undefined & loaderText3.current !== undefined) {
      timeline
      .from(loaderTransition.current, {x:0, y: -window.innerHeight, duration: 0.4, ease: "expo"})
      .from(loaderText2.current, {opacity:0, y:150, duration: 0.6, ease: "expo"}, ">")
      .from(loaderText3.current, {opacity:0, y:150, duration: 0.5, ease: "expo"}, ">")
      .to(loaderTransition.current, {
        duration: 0.05,
        x: 0,
        ease: Power4.easeOut
      })
      .to(loaderTransition.current, {
        duration: 1.5,
        y: -window.innerHeight,
        opacity: 0,
        skewY: -10,
        ease: Power4.easeInOut
      });

    } else {
      timeline
      .from(loaderTransition.current, {x:0, y: -window.innerHeight, duration: 0.5, ease: "expo"})
      .to(loaderTransition.current, {
        duration: 0.5,
        x: 0,
        ease: Power4.easeOut
      })
      .to(loaderTransition.current, {
        duration: 1.5,
        y: -window.innerHeight,
        opacity: 0,
        skewY: -10,
        ease: Power4.easeInOut
      });

    }
  }, [])

  return (
    <article>
      <LoaderContainer ref={loaderTransition}>
        {pathname === "/" ? 
          <div></div>
        : pathname === "/about" ? 
          <div>
            <FirstParagraph ref={loaderText1}>Rémi Lopez</FirstParagraph>
            <SecondParagraph ref={loaderText2}>Rémi Lopez</SecondParagraph>
            <ThirdParagraph ref={loaderText3}>Rémi Lopez</ThirdParagraph>
          </div>
        : 
          <div>
            <FirstParagraph ref={loaderText1}>{finalPathname}</FirstParagraph>
            <SecondParagraph ref={loaderText2}>{finalPathname}</SecondParagraph>
            <ThirdParagraph ref={loaderText3}>{finalPathname}</ThirdParagraph>
          </div>
        }
      </LoaderContainer>   
    </article>
  )
}
