import React from 'react';


export default function ProjetDescription({ techno, client, description, firstDesc, secondDesc, lastDesc }) {
  return (
    <section id='project__description'>
      <article id="project__description__content">
        <section id="projet__techno" className='project__description__content__item'>
          <h2 className='project__description__content__item__title'>Technologies</h2>
          <p className='project__description__content__item__text'>{techno}</p>
        </section>

        <section id="projet__client" className='project__description__content__item'>
          <h2 className='project__description__content__item__title'>Client</h2>
          <p className='project__description__content__item__text'>{client}</p>
        </section>
      </article>

      <article id='project__description__info'>
        <section className='project__description__info__item'>
          <p className='project__description__info__item__p'>{description}</p>
          <p className='project__description__info__item__p' id="projet__desc2">{firstDesc}</p>
        </section>

        <section className='project__description__info__item' style={{ marginBottom: "100px"}}>
          <p className='project__description__info__item__p'>{secondDesc}</p>
          <p className='project__description__info__item__p' id="projet__desc4">{lastDesc}</p>
        </section>
      </article>
    </section>
  )
}
