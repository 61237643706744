import React, { useEffect } from 'react';


export default function DetailDescription({ timeline }) {
   useEffect(() => {
      timeline
      .to("#about__job", {
         scrollTrigger: {
         trigger: "#detail__description_header",
         scrub: 0.5,
         start: "top 45%",
         end: "bottom -10%",
         ease: "slowMo",
         },
         y: 50,
      })
      .to("#about__localisation", {
         scrollTrigger: {
         trigger: "#detail__description_header",
         scrub: 0.5,
         start: "top 45%",
         end: "bottom -10%",
         ease: "slowMo",
         },
         y: -50,
      })
      .to('#first__block', {
         scrollTrigger: {
         trigger: '#first__block',
         scrub: 0.5,
         start: "top 80%",
         end: "bottom 20%",
         ease: "slowMo",
         },
         y: -25,
      })
      .to('#second__block', {
         scrollTrigger: {
         trigger: '#second__block',
         scrub: 0.5,
         start: "top 80%",
         end: "bottom 20%",
         ease: "slowMo",
         },
         y: -25,
      })
   }, [timeline])


  return (
      <section id="detail__description">
         <article id="detail__description_header">
            <section id="about__job" className='detail__description_header_item'>
               <h2 className='detail__description_header_item__title'>DEVELOPPEUR LOGICIEL</h2>
               {/* <p className='detail__description_header_item__text'>FREELANCE</p> */}
            </section>

            <section id="about__localisation" className='detail__description_header_item'>
               <h2 className='detail__description_header_item__title'>MARSEILLE</h2>
            </section>
         </article>

         <article id='detail__description_infos'>
            <section className='detail__description_infos__item'>
               <p className='detail__description_infos__item__p'>Actuellement architecte / développeur logiciel au sein du pôle Recherche et Développement de l'agence Digimood. Mes missions consistent principalement à concevoir des solutions internes pour répondre aux besoins de nos consultants, ainsi qu'à appliquer des maintenances évolutives et correctives sur nos applications existantes</p>
               <p className='detail__description_infos__item__p' id='first__block'>J'ai également travaillé pour des particuliers qui souhaitaient développer leur présence sur internet avec l'objectif commun d'élargir leurs champs d'actions et promouvoir leurs services. Ces missions m'ont permis d'agir sur toutes les parties d'un projet en portant successivement les casquettes de chef de projet, architecte, développeur et devops</p>
            </section>

            <section className='detail__description_infos__item'>
               <p className='detail__description_infos__item__p'>
               Je dispose d'un diplôme de Développeur Web et Web Mobile (Bac +2) obtenu auprès de l'AFPA en distanciel, ainsi qu'un diplôme de Concepteur Développeur d'Application (Bac +3/4) obtenu cette fois-ci auprès de l'école LaPlateforme à Marseille</p>
               <p className='detail__description_infos__item__p' id='second__block'>A côté de ma passion pour la technologie, je suis un fan inconditionnel de rugby, sport que j'ai pratiqué pendant une douzaine d'années, mes lectures concernent souvent la philosophie et la psychologie positive, enfin je garde un oeil sensible au domaine de l'art et du design</p>
            </section>
         </article>
      </section>
   )
}
