import React, { useEffect, useRef, useState } from 'react';

import ScrollToTop from '../../utils/ScrollToTop';

import TransitionLayout from '../../components/Loaders/TransitionLayout';
import Navbar from '../../components/NavBars/Navbar';
import HomeBanner from './components/banner';
import HomeAbout from './components/about';
import HomeProjects from './components/projects';
import Footer from '../../components/Footer/Footer';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


gsap.registerPlugin(ScrollTrigger);


export default function HomePage() {
  const homeRef = useRef();
  const homeLayout = gsap.timeline();

  const [isVisited, setIsVisited] = useState(sessionStorage.getItem("alreadyVisited"));


  useEffect(() => {
    getPreviousPath();

    if(homeRef.current !== null)
    ScrollTrigger.create({
      trigger: "#hero__projects",
      start: () => 'top -10%',
      end: () => 'bottom 60%',
      toggleClass: {targets: homeRef.current, className: "active"},
      onComplete: () => ScrollTrigger.refresh()
    });
  }, []) 

  function getPreviousPath() {
    if(isVisited === null) {
      sessionStorage.setItem("alreadyVisited", "No");
      setIsVisited(sessionStorage.getItem("alreadyVisited"));
    }
  }


  return (
    <section ref={homeRef} id="hero">
      {isVisited !== (null || "No") && <TransitionLayout timeline={homeLayout} />}

      <Navbar />
      
      <ScrollToTop>
        <HomeBanner />
        <HomeAbout timeline={ homeLayout } />

        <section id="zoom__sticky">
          <article id="zoom__sticky__project">
            <h3 id="zoom__sticky__project__text">PROJETS</h3>
          </article>
        </section>

        <HomeProjects timeline={ homeLayout } />
      </ScrollToTop>

      <Footer value="200px" />
    </section>
  )
}
