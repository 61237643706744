import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import useScrollListener from "../../utils/useScrollListener";


const NavbarHeader = styled.header`
  width: 100vw;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  transform: translateY(0%);
  transition: transform 0.4s ease-in-out;
`;

export default function NavClass({ children }) {
  const [navClass, setNavClass] = useState([]);
  const scroll = useScrollListener();

  useEffect(() => {
    const _classList = [];

    if (scroll.y > 100 && scroll.y - scroll.lastY > 0)
      _classList.push("nav-hidden");

    setNavClass(_classList);
  }, [scroll.y, scroll.lastY]);

  return (
    <NavbarHeader className={navClass.join(" ")}>
      {children}
    </NavbarHeader>
  )
}
