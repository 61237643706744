import React , { useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";

import { BsArrowUpRight } from 'react-icons/bs';

import { getProjectData } from '../../data/Projects';
import { getCollaborationData, isThisCollaboration } from '../../data/Collaborations';

import TransitionLayout from '../../components/Loaders/TransitionLayout';
import NavCloseProject from '../../components/NavBars/NavCloseProject';
import ScrollToTop from '../../utils/ScrollToTop';
import ProjetBanner from './components/banner';
import ProjetDescription from './components/description';
import ProjetSvgImages from './components/svgGsap';
import ProjetSeeNext from './components/nextProject';


import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


export default function ProjectTemplate() {
   const urlParams = useParams();
   let projetItem = [];

   if(isThisCollaboration(urlParams.slug, "true")) {
      projetItem = getCollaborationData(urlParams.slug);
   } else {
      projetItem = getProjectData(urlParams.slug);
   }

   const mainProjet = useRef();

   const projetLayout = gsap.timeline();

   let nextProject = projetItem.nextSlug.replaceAll("-", " ");
   let nexProjectDescription = projetItem.nextShortDescription;


   useEffect(() => {
      gsap.timeline()
      .delay(1.6)
      .from("#main__section", { y: 0, autoAlpha: 0, ease: "slowMo", duration: 0.6 })
      .from("#project__banner__img", { y: 0, autoAlpha: 0, ease: "slowMo", duration: 0.7 })
      .from(".letter", { y: 0, autoAlpha: 0, ease: "slowMo", duration: 0.3 }, ">")
      .to("#projet__techno", {
         scrollTrigger: {
         trigger: "#project__description__content",
         scrub: 0.5,
         start: "top 45%",
         end: "bottom -10%",
         ease: "slowMo",
         },
         y: 30,
      })
      .to("#projet__client", {
         scrollTrigger: {
         trigger: "#project__description__content",
         scrub: 0.5,
         start: "top 45%",
         end: "bottom -10%",
         ease: "slowMo",
         },
         y: -30,
      })
      .to("#projet__desc2", {
         scrollTrigger: {
         trigger: "#projet__desc2",
         scrub: 0.5,
         start: "top 80%",
         end: "bottom 20%",
         ease: "slowMo",
         },
         y: -25,
      })
      .to("#projet__desc4", {
         scrollTrigger: {
         trigger: "#projet__desc4",
         scrub: 0.5,
         start: "top 80%",
         end: "bottom 20%",
         ease: "slowMo",
         },
         y: -25,
      })
      .to("#project__svg__title", {
         scrollTrigger: {
         trigger: "#project__svg__title",
         scrub: 0.5,
         start: "top 65%",
         end: "bottom 20%",
         ease: "slowMo",
         },
         y: 25,
         x: -50,
      })
   }, [])


   return (
      <section ref={mainProjet} id='project'>
         <TransitionLayout timeline={projetLayout} />
         <NavCloseProject>{projetItem.date}</NavCloseProject>

         <ScrollToTop>
            <ProjetBanner
               imgSrc={projetItem.homeImg} 
               projectTitle={projetItem.title} 
               timeline={projetLayout}  
            />

            <ProjetDescription 
               techno={projetItem.technologies} 
               client={projetItem.client} 
               description={projetItem.description} 
               firstDesc={projetItem.mainDescription} 
               secondDesc={projetItem.secondDescription} 
               lastDesc={projetItem.thirdDescription}           
            />

            <ProjetSvgImages 
               svgOne={projetItem.imageOne}
               svgTwo={projetItem.imageTwo}
               svgThree={projetItem.imageThree}
            />

            <a 
               id='project__goto__site'
               href={projetItem.website} 
               target={"_blank"} 
               rel="noreferrer" 
               title={"Visiter le site " + projetItem.title}
            >
               <span>Voir le site</span> <BsArrowUpRight/>
            </a>              
         </ScrollToTop>

         <ProjetSeeNext 
            url={projetItem.nextSlug} 
            urlTitle={nextProject} 
            urlDescription={nexProjectDescription}
            timeline={projetLayout}
         />
      </section>
   )
}
