import React from 'react'
import { NavLink } from 'react-router-dom';


export default function CardAbout() {
  return (
   <NavLink 
      to="/about" 
      id="hero__about__item__card" 
      className="card"
   >
      <p className="card__title">Rémi lopez</p>
      <p className="card__desc">Vous pouvez cliquez si vous souhaitez en apprendre plus à mon sujet</p>

      <div id="rounded" className="card__rounded">
         <div className="card__rounded__wrapper">
            <span>P</span>
            <span>R</span>
            <span>O</span>
            <span>F</span>
            <span>I</span>
            <span>L</span>
            <span>-</span>
            <span>P</span>
            <span>R</span>
            <span>O</span>
            <span>F</span>
            <span>I</span>
            <span>L</span>
            <span>-</span>
         </div>
      </div>
   </NavLink>
  )
}
