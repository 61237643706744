import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createBrowserHistory } from "history";

import CustomCursor from './utils/CustomCursor';

import HomePage from './pages/hero';
import Detail from './pages/detail';
import ProjectTemplate from './pages/projects';

function App() {
  const history = createBrowserHistory();
  
  return (
    <Router history={history} forceRefresh={false}>
      <CustomCursor />

      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/:slug" element={<ProjectTemplate />} />
        <Route path="/about" element={<Detail />} />
        <Route path="*" element={<HomePage />} />
      </Routes> 
    </Router>
  );
}

export default App;
