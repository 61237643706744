import React from 'react';
import { BsArrowUpRight } from 'react-icons/bs';


export default function Footer({ value }) {
  const current = new Date();
  const currentYear = current.getFullYear()


  return (
    <footer style={{ marginTop: value}} id="main__footer">
      <section className='main__footer__bg'></section>
      <p className='main__footer__desc'>Vous souhaitez solliciter mon savoir-faire ? N'hésitez pas à me contacter pour que nous puissions discuter ensemble de votre projet, afin d'y apporter une solution cohérente et fonctionnelle</p>

      <section className='main__footer__links'>
        <article className='main__footer__links__wrap'>
          <a 
            className='main__footer__links__item' 
            href={"https://github.com/Zepolimer?tab=repositories"} 
            target="_blank" 
            rel="noreferrer"
          >
            <span>GITHUB</span> <BsArrowUpRight/>
          </a>

          <a 
            className='main__footer__links__item' 
            href={"https://www.linkedin.com/in/r%C3%A9mi-lopez-917b8b217/"} 
            target="_blank" 
            rel="noreferrer"
          >
            <span>LINKEDIN</span> <BsArrowUpRight/>
          </a>
        </article>

        <a 
          className='main__footer__mailto' 
          href={"Mailto:remilopez.dev@gmail.com"}
        >
          <span>@</span>
          <span>E</span>
          <span>M</span>
          <span>A</span>
          <span>I</span>
          <span>L</span>
        </a>
      </section>

      <section className='main__footer__mention'>
        <p className='main__footer__mention__siret'>N°802 266 056 00026</p>
        <p className='main__footer__mention__dev'>{currentYear} - Fait avec &#x1F9E1; par Rémi Lopez</p>
      </section>
    </footer>
  )
}
