import React, { useEffect } from 'react';
import { gsap } from "gsap";


export default function ProjetSvgImages({ svgOne, svgTwo, svgThree}) {

  useEffect(() => {
    const paths = [...document.querySelectorAll('path.path-anim')];
    
    paths.forEach(el => {
      const svgEl = el.closest('svg');
      const pathTo = el.dataset.pathTo;
  
      gsap.timeline({
        scrollTrigger: {
          trigger: svgEl,
          start: "top bottom",
          end: "bottom top",
          scrub: true
        }
      })
      .to(el, {
        ease: 'none',
        attr: { d: pathTo }
      });
    });
  }, [])

  return (
    <section id="project__svg__container">
      <h3 id="project__svg__title">Design</h3>

      <svg className="image-clip" width="1000px" height="715px" viewBox="0 0 1000 715">
        <defs>
          <clipPath id="shape8">
            <path className="path-anim" data-path-to="M 0 169 C 186.7 325 362.7 403 528 403 C 693.3 403 850.7 315 1000 169 L 1000 525 C 843.9 651.7 683.6 715 519 715 C 354.4 715 181.4 651.7 0 525 L 0 169 Z" d="M 0 169 C 174 56.3 343.7 0 509 0 C 674.3 0 838 46.3 1000 169 L 1000 525 C 831.3 383 664.6 312 500 312 C 335.4 312 168.7 383 0 525 L 0 169 Z" />
          </clipPath>
        </defs>
        <image clipPath="url(#shape8)" href={svgOne} x="0" y="0" width="1000" height="715" />
      </svg>

      <svg className="image-clip" style={{ marginTop: "-20%" }} width="1000px" height="715px" viewBox="0 0 1000 715">
        <defs>
          <clipPath id="shape9">
            <path className="path-anim" data-path-to="M 0 169 C 186.7 325 362.7 403 528 403 C 693.3 403 850.7 315 1000 169 L 1000 525 C 843.9 651.7 683.6 715 519 715 C 354.4 715 181.4 651.7 0 525 L 0 169 Z" d="M 0 169 C 174 56.3 343.7 0 509 0 C 674.3 0 838 46.3 1000 169 L 1000 525 C 831.3 383 664.6 312 500 312 C 335.4 312 168.7 383 0 525 L 0 169 Z" />
          </clipPath>
        </defs>
        <image clipPath="url(#shape9)" href={svgTwo} x="0" y="0" width="1000" height="715" />
      </svg>

      <svg className="image-clip" style={{ marginTop: "-20%" }} width="1000px" height="715px" viewBox="0 0 1000 715">
        <defs>
          <clipPath id="shape10">
            <path className="path-anim" data-path-to="M 0 169 C 186.7 325 362.7 403 528 403 C 693.3 403 850.7 315 1000 169 L 1000 525 C 843.9 651.7 683.6 715 519 715 C 354.4 715 181.4 651.7 0 525 L 0 169 Z" d="M 0 169 C 174 56.3 343.7 0 509 0 C 674.3 0 838 46.3 1000 169 L 1000 525 C 831.3 383 664.6 312 500 312 C 335.4 312 168.7 383 0 525 L 0 169 Z" />
          </clipPath>
        </defs>
        <image clipPath="url(#shape10)" href={svgThree} x="0" y="0" width="1000" height="715" />
      </svg>
    </section>
  )
}
