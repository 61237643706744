import React, { useEffect } from 'react';

import { ProjectsData } from "../../../data/Projects";
import { CollaborationsData } from "../../../data/Collaborations";

import CardProject from "./card/project";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


export default function HomeProjects({ timeline }) {
   useEffect(() => {
      const sectionsImage = gsap.utils.toArray(".project__image");
      const sectionsCard = gsap.utils.toArray(".project__card");

      timeline
      .from("#main__section", {
      y: 0,
      autoAlpha: 0,
      ease: "slowMo",
      duration: 1,
      })
      .to("#zoom__sticky__project__text", {
      duration: 2,
      scale: 90,
      autoAlpha: 0,
      ease: "power2.in",
      scrollTrigger: {
         trigger: "#hero__projects",
         start: () => "-900",
         end: () => "top -10%",
         scrub: 0.8,
         invalidateOnRefresh: true,
      },
      });

      sectionsImage.forEach((img, i) => {
         timeline.to(img, {
          scrollTrigger: {
            trigger: img,
            scrub: 0.8,
            start: () => "top 0%",
            end: () => "top -40%",
            ease: "slowMo",
          },
          scale: 0.5,
         });
      });

      sectionsCard.forEach((card, i) => {
         timeline.to(card, {
          scrollTrigger: {
            trigger: card,
            scrub: 0.8,
            start: () => "top 110%",
            end: () => "top 5%",
            ease: "slowMo",
          },
          y: -450,
          rotate: () => rotateDeg(),
         });

         function rotateDeg() {
            if (i % 2 == 0) return "5deg";
            else return "-5deg";
         }
      });
   }, []);

  return (
    <section id="hero__projects">
      {ProjectsData.map((projectData, index) => {
        return (
          <article
            className={`hero__projects__item panel panel${index}`}
            key={index}
          >
            <img
              src={projectData.homeImg}
              alt={projectData.title}
              className="project__image"
            />

            <CardProject projectData={projectData} />
          </article>
        );
      })}

      <h3 className="hero__projects__title">Collaborations</h3>

      {CollaborationsData.map((projectData, index) => {
        return (
          <article
            className={`hero__projects__item panel panel${index}`}
            key={index}
          >
            <img
              src={projectData.homeImg}
              alt={projectData.title}
              className="project__image"
            />

            <CardProject projectData={projectData} />
          </article>
        );
      })}
    </section>
  );
}
