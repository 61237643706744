import React, { useEffect } from "react";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


export default function DetailBanner() {
   useEffect(() => {
      gsap
      .timeline()
      .delay(2.8)
      .from("#detail__banner", {
        y: 0,
        autoAlpha: 0,
        ease: "slowMo",
        duration: 0.4,
      })
      .from("#detail__banner__bg", {
        y: 200,
        autoAlpha: 0,
        ease: "slowMo",
        duration: 0.7,
      })
      .from("#detail__banner__title", {
        y: 50,
        autoAlpha: 0,
        ease: "slowMo",
        duration: 0.5,
      })
      .from("#detail__banner__infos", {
        y: 50,
        autoAlpha: 0,
        ease: "slowMo",
        duration: 0.3,
      })
      .from("#detail__banner__img", {
        y: 50,
        autoAlpha: 0,
        ease: "slowMo",
        duration: 0.4,
      })
      .to("#detail__banner__infos", {
        scrollTrigger: {
          trigger: "#detail__banner",
          scrub: 0.5,
          start: "top -10%",
          end: "top -40%",
          ease: "slowMo",
        },
        x: -200,
      });

    // ANIMATE SVG
      const paths = document.querySelector(".path-anim");
      const svgElement = paths.closest("svg");
      const svgPathTo = paths.dataset.pathTo;

      gsap
      .timeline({
         scrollTrigger: {
            trigger: svgElement,
            start: "top 40%",
            end: "top 5%",
            scrub: true,
         },
      })
      .to(paths, {
         ease: "none",
         attr: { d: svgPathTo },
      });
   }, []);

  return (
    <section id="detail__banner">
      <article id="detail__banner__bg"></article>

      <h1 id="detail__banner__title">Rémi Lopez</h1>

      <p id="detail__banner__infos">01.11.1995 <br /> Marseille</p>

      <article id="detail__banner__img">
        <svg width="600px" height="600px" viewBox="0 0 600 600">
          <defs>
            <clipPath id="shape11">
              <path
                className="path-anim"
                data-path-to="M422.248 192.542c44.2 49.2 94.8 86.9 111.3 141.1 16.5 54.2-1.1 124.9-45.3 161.8-44.2 36.8-114.9 39.7-167.9 22.1-53.1-17.7-88.4-56-137.1-92.9-48.6-36.8-110.6-72.1-121.2-118.1-10.6-46 30.1-102.5 78.8-151.7 48.7-49.2 105.2-90.9 152.4-81.5 47.1 9.4 84.8 70 129 119.2"
                d="M494.246 145.646c25 39 10.2 103.8-1.2 157.2-11.4 53.4-19.5 95.3-44.5 129.3-25 34-66.9 60.1-120.6 71.7-53.6 11.7-118.9 9-163.7-25-44.8-34-69.2-99.3-71.9-167.3-2.7-68 16.2-138.7 61-177.7 44.9-39 115.6-46.3 183.4-43.4 67.7 3 132.5 16.2 157.5 55.2"
              />
            </clipPath>
          </defs>

          <image
            clipPath="url(#shape11)"
            href={process.env.PUBLIC_URL + "/images/about_remilopez.png"}
            x="0"
            y="0"
            width="600"
            height="600"
          />
        </svg>
      </article>
    </section>
  );
}
