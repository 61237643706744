export const ProjectsData = [
  {
    slug: "mina-Cisse",
    title: "Mina Cisse",
    date: "Juin 2022",
    shortDescription: "Et vous, quelle est votre vision de l'art ?",
    homeImg: `${process.env.PUBLIC_URL}/images/home__minacisse.jpg`,
    description: "Projet réalisé en freelance, pour l'artiste Mina Cissé.",
    mainDescription: "Mina Cissé est une application web, sous forme de portfolio, permettant à l'artiste du même nom de présenter ses oeuvres et ses expositions (passées ou à venir).",
    secondDescription: "Le portfolio est découpé en plusieurs parties, une page d'accueil depuis laquelle on retrouve une brève description et un aperçu de son travail, des pages présentant les peintures et créations, une page spécifique aux expositions et articles de presse et enfin, une page permettant de consulter les portfolios d'artistes coup de coeur.",
    thirdDescription: "Elle dispose d'un dashboard personnalisé lui permettant d'alimenter le contenu de son portfolio, en ajoutant, modifiant ou supprimant par exemple, une oeuvre ou un article de presse. Elle dispose également de diverses options liées à son compte.",
    imageOne: `${process.env.PUBLIC_URL}/images/projets/mina__1.png`,
    imageTwo: `${process.env.PUBLIC_URL}/images/projets/mina__2.png`,
    imageThree: `${process.env.PUBLIC_URL}/images/projets/mina__3.png`,
    technologies: "PHP, MySQL, GSAP",
    client: "Mina Cissé",
    website: "https://minacisse.com/",
    nextSlug: "saisonnier",
    nextShortDescription: "Faut-il manger 5 fruits et legumes par jour ?",
  },
  {
    slug: "saisonnier",
    title: "Saisonnier",
    date: "Avril 2022",
    shortDescription: "Faut-il manger 5 fruits et legumes par jour ?",
    homeImg: `${process.env.PUBLIC_URL}/images/home_saisonnier.jpg`,
    description: "Projet réalisé en autodidacte, faisant suite à ma formation de développeur web et web mobile. Le site répond aux standards du web concernant l'accessibilité.",
    mainDescription: "Saisonnier est une application web qui permet de connaitre rapidement les fruits, légumes, fruits à coques et aromates du mois ! Il est également possible de sélectionner l'un des douze mois de l'années pour visualiser les aliments de saison.",
    secondDescription: "Le site comprend une première section présentant le contenu du site ainsi qu'un input permettant de sélectionner un mois (par défaut, le mois en cours). Ensuite, la deuxième section comprend les aliments triés par catégories. Enfin, la troisième section reprend les différents avantages d'une consommation de produits de saison.",
    thirdDescription: "Lorsque l'on se trouve dans la deuxième section, qui dévoile les aliments du mois, on peut afficher plus d'informations en cliquant sur un aliment, peu importe sa catégorie. On retrouve par exemple son calendrier de consommation, son nutriscore ou encore une partie culture générale !",
    imageOne: `${process.env.PUBLIC_URL}/images/projets/saisonnier__1.png`,
    imageTwo: `${process.env.PUBLIC_URL}/images/projets/saisonnier__2.png`,
    imageThree: `${process.env.PUBLIC_URL}/images/projets/saisonnier__3.png`,
    technologies: "React.js, GSAP",
    client: "Rémi Lopez",
    website: "https://saisonnier.remilopez.com/",
    nextSlug: "mot-de-passe",
    nextShortDescription: "Besoin d'un mot de passe indéchiffrable ?",
  },
    {
    slug: "mot-de-passe",
    title: "Mot de passe",
    date: "Octobre 2022",
    shortDescription: "Besoin d'un mot de passe indéchiffrable ?",
    homeImg: `${process.env.PUBLIC_URL}/images/home__mdp.jpg`,
    description: "Projet réalisé en autodidacte, au cours de ma formation de concepteur et développeur d'application, en octobre 2022.",
    mainDescription: "Mot de passe est un application web permettant de générer de façon rapide un ou plusieurs mot de passe, que vous pourrez ensuite utiliser pour protéger vos accès à divers sites. Aucune donnée générée n'est enregistrée.",
    secondDescription: "Le site vous demande le type de caractères souhaités (minuscules, majuscules, numériques, caractères spéciaux) et le nombre de caractères adaptable via une jauge. Selon les options sélectionnées et le nombre de caractères, vous pouvez connaitre instantanément la résistance de votre futur mot de passe !",
    thirdDescription: "Lorsque toutes les caractéristiques vous conviennent, vous pouvez générer votre mot de passe via le bouton situé en bas du formulaire. Vous pourrez ensuite aisément copier / coller votre nouveau mot de passe. N'oubliez pas de le noter quelque part..",
    imageOne: `${process.env.PUBLIC_URL}/images/projets/mdp__1.png`,
    imageTwo: `${process.env.PUBLIC_URL}/images/projets/mdp__2.png`,
    imageThree: `${process.env.PUBLIC_URL}/images/projets/mdp__3.png`,
    technologies: "React.js",
    client: "Rémi Lopez",
    website: "https://motdepasse.remilopez.com/",
    nextSlug: "shifoumi",
    nextShortDescription: "Pouvez-vous battre un robot au shifoumi ?",
  },
  {
    slug: "shifoumi",
    title: "Shifoumi",
    date: "Juillet 2022",
    shortDescription: "Pouvez-vous battre un robot au shifoumi ?",
    homeImg: `${process.env.PUBLIC_URL}/images/home_shifoumi.jpg`,
    description: `Projet réalisé en autodidacte, au cours de ma formation de développeur web et web mobile en aout 2021, avant une refonte complète en juillet 2022.`,
    mainDescription: "Shifoumi est une application web permettant d'affronter un robot au légendaire jeu pierre-feuille-ciseaux ou via une variante comprenant en plus lézard-spock, en référence à la série Big Bang Theory.",
    secondDescription: "Une fois votre décision prise, l'affichage évolue pour montrer votre choix ainsi que celui de votre adversaire. Trois possibilités s'offrent alors à vous : un point en plus en cas de victoire, zéro point en cas d'égalité ou un point pour votre adversaire en cas de défaite.",
    thirdDescription: "Vous pouvez contrôler plusieurs paramètres comme l'IA de votre adversaire (aléatoire ou humanisé), le score à atteindre pour gagner une partie (3 ou 5), ou encore le thème (dark ou light). De plus, vous pouvez consulter vos statistiques personnelles en matière de victoires et défaites ... Bonne chance !",
    imageOne: `${process.env.PUBLIC_URL}/images/projets/shifoumi__1.png`,
    imageTwo: `${process.env.PUBLIC_URL}/images/projets/shifoumi__2.png`,
    imageThree: `${process.env.PUBLIC_URL}/images/projets/shifoumi__3.png`,
    technologies: "React.js",
    client: "Rémi Lopez",
    website: "https://www.shifoumi.remilopez.com/",
    nextSlug: "carte-de-voeux",
    nextShortDescription: "Avez-vous pensé a souhaiter une bonne annee ?",
  },
  {
    slug: "carte-de-voeux",
    title: "Carte de voeux",
    date: "Janvier 2022",
    shortDescription: "Avez-vous pensé a souhaiter une bonne annee ?",
    homeImg: `${process.env.PUBLIC_URL}/images/home_cdv.jpg`,
    description: "Projet réalisé dans le cadre de mon stage pour le bureau de design graphique Perroquet.",
    mainDescription: "Carte de voeux est une application web permettant de générer une carte de voeux de façon interactive. Sur celle-ci, on retrouve un flocon de neige dont la forme, le nombre et la taille des branches ainsi que la couleur, évoluent selon les informations renseignées dans le formulaire.",
    secondDescription: "Pour générer une carte de voeux personnalisée, rien de plus simple, saisissez le nom de votre entreprise, sa date de création et le nombre d'employés, puis cliquez sur le bouton Valider.",
    thirdDescription: "Une modal s'ouvre alors dans votre écran et contient votre carte personnalisée. Vous pouvez ensuite la télécharger au format PNG et la partager à vos collègues de bureau pour leur souhaiter une belle et heureuse année !",
    imageOne: `${process.env.PUBLIC_URL}/images/projets/cdv__1.png`,
    imageTwo: `${process.env.PUBLIC_URL}/images/projets/cdv__2.png`,
    imageThree: `${process.env.PUBLIC_URL}/images/projets/cdv__3.png`,
    technologies: "React.js",
    client: "Perroquet",
    website: "https://www.cartedevoeux.perroquet.eu/",
    nextSlug: "devnow",
    nextShortDescription: "Le site que tout developpeur web doit connaitre ?",
  },
  {
    slug: "devnow",
    title: "Devnow",
    date: "Décembre 2021",
    shortDescription: "Le site que tout developpeur web doit connaitre ?",
    homeImg: `${process.env.PUBLIC_URL}/images/home_devnow.jpg`,
    description: "Projet de fin d'étude réalisé au cours de ma formation de développeur web et web mobile.",
    mainDescription: "Devnow est une application web permettant une mise en relation entre développeurs et entreprises tech, via une liste d'emplois spécifique au développement web, mobile ou logiciel.",
    secondDescription: "Le site se décompose en trois parties : la première pour les développeurs, qui peuvent consulter les propositions d'emploi, la seconde pour les entreprises, qui via un dashboard peuvent poster, modifier et supprimer des annonces et la troisième pour l'administrateur.",
    thirdDescription: "Le site est connecté à une base de données SQL permettant de conserver les annonces saisies par les différentes entreprises. L'administrateur du site possède les droits suffisants pour effectuer des opérations en bases de données concernant les annonces et ses utilisateurs.",
    imageOne: `${process.env.PUBLIC_URL}/images/projets/devnow__1.png`,
    imageTwo: `${process.env.PUBLIC_URL}/images/projets/devnow__2.png`,
    imageThree: `${process.env.PUBLIC_URL}/images/projets/devnow__3.png`,
    technologies: "PHP, MySQL",
    client: "Rémi Lopez",
    website: "https://devnow.remilopez.com/",
    nextSlug: "fsv",
    nextShortDescription: "Êtes-vous intéressé(e) par la FoodTech ?",
  },
  // {
  //   slug: "Coup-de-balai",
  //   title: "Coup de balai",
  //   date: "Décembre 2021",
  //   shortDescription: "C'est pas encore l'heure du grand menage de printemps ?",
  //   homeImg: `${process.env.PUBLIC_URL}/images/home_cdb.jpg`,
  //   description: "Projet réalisé pour la société Coup de Balai.",
  //   mainDescription: "Coup de Balai est un site vitrine permettant à la société de bénéficier d'une présence et visibilité sur internet, dans l'optique de proposer des services de nettoyage à des particuliers et professionnels.",
  //   secondDescription: "La page d'accueil du site permet de comprendre directement le type de services proposés et de contacter la société. On découvre ensuite une description ainsi qu'un slider illustrant les activités, une partie détaillant les prestations proposées et une dernière partie permettant de contacter la société.",
  //   thirdDescription: "Son design, simple et épuré permet à l'utilisateur de rapidement comprendre le domaine d'activité et fait également référence à la prestation principale qui est le ménage : tout est ordonné et à sa place !",
  //   imageOne: `${process.env.PUBLIC_URL}/images/projets/cdb__1.png`,
  //   imageTwo: `${process.env.PUBLIC_URL}/images/projets/cdb__2.png`,
  //   imageThree: `${process.env.PUBLIC_URL}/images/projets/cdb__3.png`,
  //   technologies: "React.js",
  //   client: "Coup de Balai",
  //   website: "https://www.coupdebalai.net/",
  //   nextSlug: "Fsv",
  //   nextShortDescription: "Êtes-vous intéressé(e) par la FoodTech ?",
  // },
]

export function getProjectData(slug) {
  return ProjectsData.find(
    (data) => data.slug === slug
  );
}