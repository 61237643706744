import React from 'react';
import { NavLink } from "react-router-dom";
import styled from 'styled-components';
import NavClass from "./NavClass";


const NavbarContainer = styled.nav`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Anchor = styled(NavLink)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 5%;

  color: rgb(255, 255, 255);
  text-decoration: none;
  font-weight: 100;
  overflow: hidden;

  transition: color 0.7s ease, letter-spacing 0.7s ease;

  &:hover {
    color: rgb(178, 243, 93);
    letter-spacing: 1px;
  }
`;
const AnchorLogo = styled.img`
  width: 50px;

  transition: transform 0.9s ease;
  &:hover {
    transform: translate(10px, 10px) skew(10deg);
  }
`

const AnchorAbout = styled(NavLink)`
  padding: 0 5%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  text-decoration: none;
  font-size: 16px;
  color: #2F2C1C;
  text-transform: uppercase;
  font-weight: 400;
  border-radius: 40px 0 0 40px;

  transition: transform 0.9s ease;

  span {
    display: inline-block;
    position: relative;
  }
  span:nth-of-type(1) { transition: transform 0.6s ease; }
  span:nth-of-type(2) { transition: transform 0.9s ease; }
  span:nth-of-type(3) { transition: transform 1.2s ease; }
  span:nth-of-type(4) { transition: transform 1.5s ease; }
  span:nth-of-type(5) { transition: transform 1.8s ease; }
  span:nth-of-type(6) { transition: transform 2.1s ease; }
  span:nth-of-type(7) { transition: transform 2.4s ease; }
  span:nth-of-type(8) { transition: transform 2.7s ease; }

  &:hover {
    transform: translateX(-15px);

    span:nth-of-type(1) { transform: translateY(5px) skew(-10deg); }
    span:nth-of-type(2) { transform: translateY(-10px) skew(5deg); }
    span:nth-of-type(3) { transform: translateY(-10px) skew(10deg); }
    span:nth-of-type(4) { transform: translateY(-10px) skew(-3deg); }
    span:nth-of-type(5) { transform: translateY(5px) skew(-10deg); }
    span:nth-of-type(6) { transform: translateY(-10px) skew(5deg); }
    span:nth-of-type(7) { transform: translateY(5px) skew(8deg); }
    span:nth-of-type(8) { transform: translateY(-10px) skew(-3deg); }
  }
`;


export default function Navbar() {

  return (
    <NavClass>
      <NavbarContainer>
        <Anchor to="/">
          <AnchorLogo src={process.env.PUBLIC_URL + '/images/rlpz_logo.png'}/>
        </Anchor>
        
        <AnchorAbout to="/about">
          <span>A</span>
          <span>&nbsp;</span>
          <span>p</span>
          <span>r</span>
          <span>o</span>
          <span>p</span>
          <span>o</span>
          <span>s</span>
        </AnchorAbout>
      </NavbarContainer>
    </NavClass>
  )
}