import React, { useEffect } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


export default function HomeBanner() {
   const isFirst = sessionStorage.getItem("alreadyVisited");
 
   useEffect(() => {
     gsap.timeline()
     .delay(isFirst === (null || "No") ? 0 : 0.8)
     .from('#hero__banner', { y: 0, autoAlpha: 0, ease: "slowMo", duration: 0.4 })
     .from('#hero__banner__bg', { y: 200, autoAlpha: 0, ease: "slowMo", duration: 0.7 })
     .from('#hero__banner__subtitle', { y: 100, autoAlpha: 0, skewX: "5deg", ease: "slowMo", duration: 0.5 })
     .from('#hero__banner__location', { y: 50, autoAlpha: 0, skewX: "-5deg", ease: "slowMo", duration: 0.3 })
     .from('#hero__banner__title', { x: -100, autoAlpha: 0, skewX: "5deg", ease: "slowMo", duration: 0.4 }, '<')
     .to('#hero__banner__location', {
       scrollTrigger: {
         trigger: '#hero__banner',
         scrub: 0.5,
         start: "top -10%",
         end: "top -40%",
         ease: "slowMo",
       },
       x: 150,
     })
     .to('#hero__banner__title', {
       scrollTrigger: {
         trigger: '#hero__banner__title',
         scrub: 0.5,
         start: "top 55%",
         end: "bottom -250px",
         ease: "slowMo",
       },
       y: 175,
     })
   }, [])

  return (
   <section id="hero__banner">
      <article id="hero__banner__bg"></article>

      <h1 id="hero__banner__title">Developpeur logiciel</h1>
      <p id="hero__banner__location">43 ° 18 '02.8 N <br/> 5 ° 23 '25.5 E</p>
      <h2 id="hero__banner__subtitle">Remi Lopez</h2>
   </section>
  )
}
