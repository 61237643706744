import React, { useEffect } from "react";

import {RiDoubleQuotesL} from "react-icons/ri"
import {RiDoubleQuotesR} from "react-icons/ri"
import CardAbout from "./card/about";


export default function HomeAbout({ timeline }) {
   useEffect(() => {
     timeline.to('#hero__about__line', {
       scrollTrigger: {
         trigger: '#hero__about',
         scrub: true,
         start: () => "top 30%",
         end: () => "top 15%",
         ease: "slowMo",
       },
       height: 0
     })
     timeline.to('#hero__about__item__text', {
       scrollTrigger: {
         trigger: '#hero__about__item__text',
         scrub: true,
         start: () => "top 40%",
         end: () => "bottom 10%",
         ease: "slowMo",
       },
       y: 75
     })
     timeline.to('#hero__about__item__card', {
       scrollTrigger: {
         trigger: '#hero__about__item__card',
         scrub: true,
         start: () => "top 90%",
         end: () => "bottom -200px",
         ease: "slowMo",
       },
       y: -350,
       rotate: "10deg"
     })
   }, [window])


  return (
   <section id="hero__about">
      <div className="hero__about__line" id="hero__about__line"></div>

      <article className="hero__about__item">
         <p className="hero__about__item__text" id="hero__about__item__text">
            <RiDoubleQuotesL />J'aime relever de nouveaux défis en concevant et développant des solutions logicielles et applicatives sur mesure, adaptées aux besoins spécifiques de chaque client ou entreprise<RiDoubleQuotesR />
         </p>

         <CardAbout />
      </article>
   </section>
  )
}
