import React, { useEffect } from "react";


export default function ProjetBanner({ imgSrc, projectTitle, timeline }) {
  const projectTitleSpan = projectTitle.split("");

  useEffect(() => {
    timeline.to("#project__banner__title", {
      scrollTrigger: {
        trigger: "#project__banner",
        scrub: 0.5,
        start: "top -10%",
        end: "bottom 20%",
        ease: "slowMo",
      },
      y: 75,
    });
  }, []);

  
  return (
    <article id="project__banner">
      <img
        src={imgSrc}
        alt={`image du projet ${projectTitle}`}
        id="project__banner__img"
      />

      <h1 id="project__banner__title">
        {projectTitleSpan.map((item, index) => {
          return (
            <span key={index} className="letter">
              {item}
            </span>
          );
        })}
      </h1>

      <div id="rounded" className="card__rounded project__rounded">
        <div className="card__rounded__wrapper">
          <span>S</span>
          <span>C</span>
          <span>R</span>
          <span>O</span>
          <span>L</span>
          <span>L</span>
          <span>-</span>
          <span>S</span>
          <span>C</span>
          <span>R</span>
          <span>O</span>
          <span>L</span>
          <span>L</span>
          <span>-</span>
        </div>
      </div>
    </article>
  );
}
