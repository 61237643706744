import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";


export default function CustomCursor() {
  const location = useLocation();

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [hidden, setHidden] = useState(true);
  const [linkHovered, setLinkHovered] = useState(false);
  const [imgHovered, setImgHovered] = useState(false);

  useEffect(() => {
    addEventListeners();
    handleLinkHoverEvents();
    handlePicHoverEvents();
    return () => removeEventListeners();
  }, [location]);

  const addEventListeners = () => {
    document.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseenter", onMouseEnter);
    document.body.addEventListener("mouseleave", onMouseLeave);
    document.addEventListener("mousedown", onMouseDown);
    document.addEventListener("mouseup", onMouseUp);
  };

  const handleLinkHoverEvents = () => {
    document.querySelectorAll("a").forEach(el => {
      el.addEventListener("mouseover", () => setLinkHovered(true));
      el.addEventListener("mouseout", () => setLinkHovered(false));
    })
  }
  const handlePicHoverEvents = () => {
    document.querySelectorAll("img").forEach(el => {
      el.addEventListener("mouseover", () => setImgHovered(true));
      el.addEventListener("mouseout", () => setImgHovered(false));
    })
  }

  const removeEventListeners = () => {
    document.removeEventListener("mousemove", onMouseMove);
    document.body.removeEventListener("mouseenter", onMouseEnter);
    document.body.removeEventListener("mouseleave", onMouseLeave);
    document.removeEventListener("mousedown", onMouseDown);
    document.removeEventListener("mouseup", onMouseUp);
  };

  const onMouseMove = (e) => { 
    setPosition({ x: e.clientX, y: e.clientY }); 
    setHidden(false) 
  }

  const onMouseLeave = () => { setHidden(true) }
  const onMouseEnter = () => { setHidden(false) }

  const onMouseDown = () => { setLinkHovered(true) }
  const onMouseUp = () => { setLinkHovered(false) }

  return (
    <div style={{ 
      left: `${position.x}px`, 
      top: `${position.y}px`, 
      opacity: hidden ? "0" : "1",
      transform: linkHovered ? "translate(-50%, -50%) scale(2)" : imgHovered ? "translate(-50%, -50%) scale(1.5)" : "translate(-50%, -50%) scale(1)",
      backgroundColor: linkHovered ? "#ffffff10" : imgHovered ? "#F0AB5D60" : "#F0AB5D80", 
      border: linkHovered || imgHovered ? "1px solid #F0AB5D" : "2px solid #F0AB5D80",
      }}
      className="cursor" 
      id="cursor"></div>
  );
}
