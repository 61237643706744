import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";

import NavClass from "./NavClass";
import { IoArrowBackSharp } from "react-icons/io5";


const NavbarContainer = styled.nav`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Anchor = styled(NavLink)`
  padding: 0 5%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-transform: uppercase;
  overflow: hidden;

  color: #2F2C1C;
  text-decoration: none;
  font-size: 34px;
  border-radius: 40px 0 0 40px;

  transition: 0.7s ease;

  &:hover {
    transform: translateX(-15px);
  }
`;

const AnchorTitle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 5%;
`;

const AnchorTitleText= styled.p`
  color: #2F2C1C;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  ${'' /* font-style: italic; */}
`;


export default function NavCloseProject({ children }) {

  const { pathname } = useLocation();
  const path = pathname;
  const slicedPathname = path.substring(1);
  const finalPathname = slicedPathname.replaceAll('-', ' ');

  function addToSession() {
    var visited = sessionStorage.getItem("alreadyVisited");
    if(visited !== "") {
      sessionStorage.setItem("alreadyVisited", finalPathname);
    }
  }

  useEffect(() => { addToSession(); }, [])

  return ( 
    <NavClass>
      <NavbarContainer>
        <AnchorTitle>
          <AnchorTitleText>{children}</AnchorTitleText>
        </AnchorTitle>

        <Anchor to="/">
          <IoArrowBackSharp />
        </Anchor>
      </NavbarContainer>
    </NavClass>
  );
}