import React from "react";
import { NavLink } from "react-router-dom";


export default function CardProject({ projectData }) {
  return (
    <NavLink 
      className="card project__card" 
      to={"/" + projectData.slug} 
      state={{ projectData }}
    >
      <p className="card__title">{projectData.title}</p>
      <p className="card__desc">{projectData.shortDescription}</p>

      <div id="rounded" className="card__rounded">
        <div className="card__rounded__wrapper">
          <span>P</span>
          <span>R</span>
          <span>O</span>
          <span>J</span>
          <span>E</span>
          <span>T</span>
          <span>-</span>
          <span>P</span>
          <span>R</span>
          <span>O</span>
          <span>J</span>
          <span>E</span>
          <span>T</span>
          <span>-</span>
        </div>
      </div>
    </NavLink>
  );
}
