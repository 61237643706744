import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

export default function ProjetSeeNext({
  url,
  urlTitle,
  urlDescription,
  timeline,
}) {
  useEffect(() => {
    timeline
      .from("#next__project__footer", {
        y: 0,
        autoAlpha: 0,
        ease: "slowMo",
        duration: 1,
      })
      .to("#next__project__card", {
        scrollTrigger: {
          trigger: "#next__project__footer",
          scrub: 0.5,
          start: "top 55%",
          end: "top 20%",
          ease: "slowMo",
        },
        y: -200,
      });
  }, []);

  return (
    <footer id="next__project__footer">
      <section id="next__project__footer__bg"></section>

      <section id="next__project__footer__marquee">
        <span>
          Projet suivant - Projet suivant - Projet suivant - Projet suivant -
          Projet suivant - Projet suivant
        </span>
      </section>

      <NavLink
        id="next__project__card"
        className="card"
        to={"/" + url}
        reloadDocument
      >
        <p className="card__title">{urlTitle}</p>
        <p className="card__desc">{urlDescription}</p>

        <div id="rounded" className="card__rounded">
          <div className="card__rounded__wrapper">
            <span>P</span>
            <span>R</span>
            <span>O</span>
            <span>J</span>
            <span>E</span>
            <span>T</span>
            <span>-</span>
            <span>P</span>
            <span>R</span>
            <span>O</span>
            <span>J</span>
            <span>E</span>
            <span>T</span>
            <span>-</span>
          </div>
        </div>
      </NavLink>
    </footer>
  );
}
