import React, { useEffect, useState } from 'react';

import TransitionLayout from '../../components/Loaders/TransitionLayout';
import NavCloseProject from '../../components/NavBars/NavCloseProject';
import ScrollToTop from '../../utils/ScrollToTop';
import DetailBanner from './components/banner';
import DetailDescription from './components/description';
import Footer from '../../components/Footer/Footer';


import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { BsArrowUpRight } from 'react-icons/bs';
gsap.registerPlugin(ScrollTrigger);


export default function Detail() {
   const d = new Date();
   const hour = d.getHours();
   const [sayHello, setSayHello] = useState("")

   const aboutLayout = gsap.timeline();
 
   useEffect(() => {
      if(hour > 18 || hour < 4) setSayHello("Bonsoir");
      else setSayHello("Bonjour");

      aboutLayout
      .from("#main__section", { 
         y: 0, 
         opacity: 0, 
         ease: "slowMo", 
         duration: 1 
      })
      .to('#zoom__hello', {
         duration: 2,
         scale: 90,
         autoAlpha: 0,
         ease: 'power2.in',
         scrollTrigger: {
         trigger: "#detail__description",
         start: () => '-700',
         end: () => 'top -10%',
         scrub: 0.8,
         invalidateOnRefresh:true,
         }
      })
      .to("#detail__quote", {
         scrollTrigger: {
         trigger: "#detail__quote",
         scrub: 0.5,
         start: "top 30%",
         end: "top 15%",
         ease: "slowMo",
         },
         y: -20,
      })
      .to("#detail__goto__git", {
         scrollTrigger: {
         trigger: "#detail__goto__git",
         scrub: 0.5,
         start: "top 45%",
         end: "top 20%",
         ease: "slowMo",
         },
         y: 50,
      })

      ScrollTrigger.create({
         trigger: "#detail__description",
         start: () => 'top 20%',
         end: () => 'bottom 300px',
         toggleClass: {targets: '#detail', className: "active"},
         onComplete: () => ScrollTrigger.refresh()
      });
   }, [hour])


   return (
      <section id='detail'>
         <TransitionLayout timeline={aboutLayout} />
         <NavCloseProject />

         <ScrollToTop>
            <DetailBanner />

            <section id='detail__sticky'>
               <article id='detail__sticky__zoom'>
                  <h3 id="zoom__hello">{sayHello}</h3>
               </article>
            </section>

            <DetailDescription timeline={aboutLayout} />
        
            <p id="detail__quote">La vocation n'est que le resultat de la pratique</p>

            <section id="detail__goto__git">
               <a 
                  href={"https://github.com/Zepolimer?tab=repositories"} 
                  target="_blank" 
                  rel="noreferrer"
               >
                  <span>Voir tous les projets (+40)</span> <BsArrowUpRight/>
               </a>
            </section>
         </ScrollToTop>

         <Footer value="250px" />
      </section>
   )
}
